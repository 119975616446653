/* inputs */
input {
    display: block;
    margin-bottom: 3.6rem;
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--mid-gray);
    color: var(--dark-gray);
    padding: 0.8rem 0;
    font-weight: var(--font-weight-light);
    font-family: var(--font-family);
}

input,
input::placeholder {
    font-size: 2rem;
    line-height: 2.7rem;
    color: var(--dark-gray);
}

input:focus {
    border-bottom: 1px solid var(--petrol);
    outline: none;
}

button {
    outline: none;
}

.no-scroll {
    overflow: hidden;
}

/* buttons */
.button-primary,
.button-secondary {
    border-radius: 3.2rem;
    padding: 1.5rem 0;
    font-size: 2rem;
    width: 100%;
    font-family: var(--font-family);
    font-weight: var(--font-weight-regular);

    @media (--tablet) {
        width: 27rem;
    }

    &:hover {
        opacity: 0.7;
    }
}

.button-primary {
    background-color: var(--petrol);
    border: none;
    color: var(--white);

    &--disabled {
        background-color: var(--light-gray);
        color: var(--mid-gray);

        &:hover {
            opacity: 1;
            cursor: auto;
        }
    }
}

.button-secondary {
    background-color: var(--white);
    border: 2px solid var(--petrol);
    color: var(--petrol);

    &--disabled {
        background-color: var(--light-gray);
        color: var(--mid-gray);
        border: none;

        &:hover {
            opacity: 1;
            cursor: auto;
        }
    }
}

.collapse-css-transition {
    transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 3rem;
        width: 100%;
        background: var(--gradient-white);
        transition: opacity 0.3s;
    }

    &.--c-expanded::after,
    &.--c-expanding::after {
        opacity: 0;
    }
}
