.overWriteSurvey {
  padding-bottom: 12rem !important;
  width: 100vw !important;
  transform: translateX(calc(-1 * var(--gutter))) !important;
  margin: 0 auto !important;
  line-height: normal !important;
}

.firstPage-container {
  position: relative;
  
  .page-wrapper {
    h1 {
      font-size: 2.2rem;
      font-weight: 600;
      margin: 2.5rem 0 .8rem;
      line-height: normal;
    }
    p {
      font-size: var(--p-larger);
      line-height: 2.7rem;
    }
  
    .instructions-container {
      .text-container {
        margin: 4rem auto;
        
        p {
          margin-top: 3rem;
        }
      }
    }
    
    .cards-container {
      min-height: 4rem;
      padding: 0 1rem;
    }
    .blue {
      border: 2px solid #07586B;
  
      .blueBtn {
        display: none;
      }
    }
    .yellow {
      border: 2px solid #FEC378;
  
      .yellowBtn {
        display: none;
      }
    }
    .orange {
      border: 2px solid #FF6E45;
  
      .orangeBtn {
        display: none;
      }
    }
  
    .questions-container {
      margin: 3rem auto;
  
      @media screen and (min-width: 800px) {
        margin-top: 6.5rem;
      }
    }
  
    .si-page-width {
      max-width: var(--wide-page-width);
      margin-left: auto;
      margin-right: auto;
    }
  }
}


.card-component-container {
  width: 100%;
  padding: 2rem 2rem;
  border-radius: 7px;
  box-shadow: 0px 0px 10px -4px;
  margin: 2rem 0;

  @media screen and (min-width: 800px) {
    width: 80%;
    margin: 2rem auto;
  }

  .question-text-container {
    display: flex;
    align-items: center;
    margin: 1rem auto 2rem;
    h1 {
      display: flex;
      align-items: center;
      margin: 0;
      font-size: 1.5rem;
      font-weight: 400;

      @media screen and (min-width: 800px) {
        font-size: 1.8rem;
      }
    }
    .questionRanking {
      font-size: 1.7rem;
      padding: .3rem 1rem;
      border-radius: 50%;
      border: 2px solid #FF6E45;
      margin: 0 1rem;
      word-break: normal;

      @media screen and (min-width: 500px) {
        margin: 0 1.5rem;
      }
    }
  }

  .more-text-container {
    border-top: 2px solid var(--light-gray);
    margin: 3rem 0;
    position: relative;

    .text-controls {
      position: absolute;
      top: -1rem;
      right: 0;
      width: 40%;
      background: white;
      display: flex;
      justify-content: center;
      cursor: pointer;
      @media screen and (min-width: 500px) {
        width: 20%;
      }
      
      .showOrHide {
        color: #07586B;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        margin: 0 .5rem;
      }

      .chevron-default {
        font-size: 2rem;
        transition: .3s;
        display: flex;
        align-items: center;
      }
    }

    .text-card {
      padding: 2rem 0;
      border-bottom: 2px solid var(--light-gray);

      ul {
        list-style-type: disc;
        padding: 0 1.5rem 0;

        @media screen and (min-width: 800px) {
          padding: 0 4rem 0;
        }

        li {
          font-size: 1.4rem;
          margin: 1rem auto;

          @media screen and (min-width: 800px) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;

    button {

      width: 30%;
      margin: 0 .2rem;
      padding: 1rem .2rem;
      border-radius: 2rem;
      background-color: white;
      font-size: 1rem;
      font-weight: 600;
      border: 1px solid;

      @media screen and (min-width: 500px) {
        width: 25%;
        padding: 1.4rem .5rem;
        margin: 0 1rem;
        font-size: 1.3rem;
      }

      @media screen and (min-width: 800px) {
        padding: 1.7rem 1.2rem;
      }
    }
    
    .blueBtn {
      color: #07586B;
      border-color: #07586B;

      &:hover {
        color: white;
        background: #07586B;
      }
    }
    .yellowBtn {
      color: #FEC378;
      border-color: #FEC378;

      &:hover {
        color: white;
        background: #FEC378;
      }
    }
    .orangeBtn {
      color: #FF6E45;
      border-color: #FF6E45;

      &:hover {
        color: white;
        background: #FF6E45;
      }
    }
    .arrow {
      font-size: 2.3rem;
      width: auto;
      height: auto;
      padding: 0;
      svg {
        color: #07586B;
        border: none;
        border-radius: 50%;

        @media screen and (min-width: 800px) {
          font-size: 3rem;
        }
        
        &:hover {
        background-color: #07586B;
        color: white;
        }
        &:active {
          opacity: .6;
        }
      }
    }
  }

  .disabledBtn {
    color: gray !important;
    border-color: gray !important;
    opacity: .5 !important;
    
    &:hover {
      background-color: transparent !important;
      cursor: not-allowed;
    }
    &:active {
      cursor: not-allowed !important
    }
  }
  .d-none {
    display: none;
  }
  .d-block {
    display: block;
  }
  .rotate-up {
    transform: rotate(180deg);
    transition: .3s;
  }
}