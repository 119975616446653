.cStyle-results-container {
  @media screen and (min-width: 800px) {
    max-width: var(--wide-page-width);
    margin: 0 auto;
  }

  .location-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      text-transform: uppercase;
      font-size: var(--subtitle);
      line-height: 1.6rem;
      letter-spacing: .1em;
      margin-bottom: 1.2rem;
    }

    span {
      color: #000;
      font-weight: 700;
      font-size: var(--subtitle);
      
      &:before {
        content: ">";
        color: grey;
        padding: 0 5px;
      }
    }

    .retake-btn {
      width: 50%;
      text-align: right;
    }
  }

  .c-style-name-container {
    z-index: 2;
    margin: 2rem 0;
    height: 10rem;
    width: calc(100% + 2 * var(--gutter));
    transform: translateX(calc(-1 * var(--gutter)));
    overflow: hidden;
    position: relative;

    @media (--desktop) {
      height: 11rem;
    }

    svg {
      width: 130%;

      @media (--desktop) {
        transform: scale(1.8) translateY(+23%);
      }
    } 

    .style-text-container {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      padding: 1.5rem 2rem;

      @media (--tablet) {
        padding: 2rem 3rem;
      }

      h6 {
        text-transform: uppercase;
        font-size: 1.5rem;
        padding-top: 1rem;
      }
    }
  }

  .static-text {
    font-size: var(--p-larger);
    margin-top: 2rem;
  }

  .main-zone-paragraph {
    h1 {
      font-size: 2.2rem;
      font-weight: 600;
      margin: 2.5rem 0 .8rem;
      line-height: normal;
    }
  }

  .personalised-video-container {
    padding-top: 0;
    margin: 3rem auto;

    h1 {
      font-size: 2.2rem;
      font-weight: 600;
      margin-bottom: .8rem;
      line-height: normal;
    }
    p {
      font-size: var(--p-larger);
      margin-bottom: 1.5rem;
      @media screen and (min-width: 800px) {
          margin-bottom: 2.5rem;
      }
    }
  }

  .recommended-video-container {
    padding: 2rem 0;
    margin-bottom: 1rem;
    h1 {
      font-size: 2.2rem;
      font-weight: 600;
    }

    .videos-container {
      display: flex;
      justify-content: space-between;
      padding-top: 2.5rem;

      .round-gradient {
        transition: .5s;
        cursor: pointer;
        border-radius: 50%;
        background-size: cover;
        height: 100px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .cstyle-picture {
          background-size: 100px;
          background-position-x: 50%;
          background-position-y: 50%;
          height: 90%;
          width: 90%;
          border-radius: 50%;
        }
        svg {
          position: absolute;
          top: calc(50% - 15px);
          left: calc(50% - 15px);
          color: var(--orange);
          width: 30px; 
          height: 30px;
        }
      }
      .round-gradient:hover {
        opacity: .7;
        transform: scale(1.1);
        transition: .5s;
      }

      .video-labels-container {
        padding-top: 1.5rem;
        h1, p {
          text-align: center;
          line-height: 2rem;
          font-size: 1.5rem;
        }
      }

      @media screen and (min-width: 700px) {
        justify-content: space-evenly;

        .round-gradient {
          height: 150px;
          width: 150px;

          .cstyle-picture {
            background-size: 150px;
          }
        }
      }   
    }
  }

  .email-container {
    width: calc(100% - 2 * var(--gutter));
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 2rem;
    box-shadow: 0 0 10px 3px lightgrey;
    @media screen and (min-width: 800px) {
      max-width: var(--wide-page-width-minus-gutter);
      margin: auto auto;
    }

    button {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      padding: 0.3rem;
      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
      h1 {
        display: none;
      }
      @media screen and (min-width: 800px) {
        padding: 1rem;
        h1 {
          display: block;
          font-size: 2.2rem;
          padding-left: 1rem;
          line-height: normal;
        }
      }
    }
    .sendEmailBtn {
      margin-right: 1rem;
    }
    .sendInviteBtn {
      margin-left: 1rem;
    }
  }
  .customHR {
    width: calc(90% - 2 * var(--gutter));
    border-top: 3px solid lightgray;
    border-radius: 1px;
    margin: 0 auto 3rem auto;
    @media screen and (min-width: 800px) {
      max-width: var(--wide-page-width-minus-gutter);
      margin: 2rem auto 5rem auto;
    }
  }

  .h30px {
    position: relative;
    height: 30px;
  }

}

.bottom-btns-container {
  display: block;
  position: relative;
  padding-bottom: 20px;
  margin-top: 3rem;

  button {
    display: block;
    width: 100%;
    margin: .8rem auto;
  }

  @media (--tablet) {
    display: flex;
    justify-content: space-between;

    button {
      width: 47%;
    }
  }
}