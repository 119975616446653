.c-style-graph {
  margin: 4rem auto;

  @media (--tablet) {
      margin: 6rem auto;
  }

  width: 100%;
  height: 0;
  padding-top: 79%;
  position: relative;

  @media (--tablet) {
      padding-top: 42%;
  }

  h3 {
      font-size: var(--h4);
      line-height: 2.7rem;

      @media (--tablet) {
          font-size: var(--h3);
          line-height: 3.4rem;
      }
  }

  &-wrapper {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      cursor: pointer;

      &::before {
          content: "";
          position: absolute;
          left: calc(50% - 0.1rem);
          top: 0;
          bottom: 0;
          width: 0.2rem;
          background: #e1e1e2;
      }
      &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: calc(50% - 0.1rem);
          height: 0.2rem;
          background: #e1e1e2;
      }

      .labels {
          position: absolute;
          background-color: var(--white);
          z-index: 1;
          color: var(--mid-gray);
          font-size: var(--p);
          line-height: 2.5rem;

          &__introvert,
          &__extrovert {
              top: calc(50% - 1.4rem);
          }

          &__introvert,
          &__facts {
              padding-right: 0.5rem;
          }

          &__extrovert,
          &__people {
              padding-left: 0.5rem;
          }

          &__introvert {
              left: 0;
          }

          &__extrovert {
              right: 0;
          }

          &__facts,
          &__people {
              transform-origin: left;
              left: calc(50% - 0.1rem);
              transform: rotate(-90deg);
          }

          &__facts {
              bottom: -2rem;
          }

          &__people {
              top: 3.4rem;
          }
      }

      .handleLongExtrovert {
        width: 10rem;
        transform: translateX(-2rem);

        @media (--tablet) {
            width: 12rem;
            transform: translateX(-3rem);
        }
      }

      .connecting,
      .influencing,
      .examining,
      .delivering {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          color: var(--petrol);
      }

      .influencing,
      .delivering {
          align-items: flex-end;
      }

      .connecting,
      .influencing {
          margin-top: -1rem;
      }

      .examining,
      .delivering {
          justify-content: flex-end;
          margin-bottom: -1rem;
      }

      p.smaller {
          color: var(--orange);
      }

      &__dot-boundary {
          position: absolute;
          top: 7rem;
          right: 7rem;
          left: 7rem;
          bottom: 7rem;
      }
      .dot {
          position: absolute;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          background: rgba(255, 110, 69, 1);
          transform: translate(-.8rem, -.8rem);
          z-index: 1;

          &::before, &::after {
              content: "";
              position: absolute;
              height: 100%;
              width: 100%;
              background: rgba(255, 110, 69, 1);
              border-radius: 50%;
              z-index: 0;
              opacity: .7;
          }

          &::before {
              animation: pulse 2s ease-out infinite;
          }

          &::after {
              animation: pulse 2s 1s ease-in-out infinite;
          }
      }
      @keyframes pulse {
          100% {
              transform: scale(2);
              opacity: 0;
          }
      }
  }
}
.label-container {
  position: relative;
  width: 80%;
  height: calc(var(--h3) + 20px);
  overflow: hidden;
  border-radius: 50px;
  transform: translate(-20px -20px);
}
.labels-mutual {
  position: absolute;
  height: 1200px;
  width: 1200px;
}
.labels-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.labels-text h3 {
  width: 70%;
  font-size: 2rem !important;
  font-weight: 600;
  text-align: center;
  padding-left: 10px;
}
.labels-text span {
  width: 30%;
  display: flex;
  align-items: center;
  font-size: 3rem;
  font-weight: 400;
}

@media screen and (max-width: 500px) {
  .label-container {
      width: 90%;
      height: calc(1.5rem + 25px);
  }
  .labels-text h3 {
      font-size: 1.5rem !important;
     
  }
  .labels-text span {
     
      font-size: 2rem;
      padding-right: 0.5rem;
  }
} 

.labels-video-player {
  display: none;
}
.labels-video-playing {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(255,255,255,.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-container {
  position: relative;

  .video-close {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
  }
}


.dot-container {
  position: absolute;
}
.dot-here-text {
  position: relative;
  top: 2.7rem;
  left: -1.5rem;
  width: 5rem;
  font-weight: bold;
  z-index: 2;

  @media screen and (min-width: 800px) {
      width: 6rem;
  }
}

.hide {
  display: none !important;
}
.show {
  display: block;
}