.topics-tile {
    cursor: pointer;

    &__item {
        display: flex;
        flex-direction: column;
        position: relative;

        &__image {
            background-color: var(--light-gray);
            width: 100%;
            padding-top: 100%;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
            }

            &__title {
                position: absolute;
                bottom: 2rem;
                left: 1.2rem;
                right: 1.2rem;
                color: var(--white);
                font-size: 3.1rem;
                line-height: 3.7rem;
            }
        }

        &--is-detail {
            .topics-tile__item__image {
                @media (--tablet) {
                    height: 38rem;
                }
            }
        }

        &__title,
        &__description p {
            @media (--tablet) {
                width: 30rem;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__description {
            display: none;

            @media (--tablet) {
                display: block;
            }

            &--vertical {
                display: block;
                width: 100%;
            }
        }

        &__title {
            color: var(--petrol);
            font-size: 2rem;
            line-height: 2.7rem;
            margin-top: 1.2rem;

            &--is-detail {
                margin-bottom: 2rem;
            }
        }

        &__description {
            color: var(--dark-gray);
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin-top: 0.4rem;
        }

        &__podcast,
        &__completed-label {
            position: absolute;
        }

        &__podcast {
            top: 0.8rem;
            left: 0.8rem;
        }

        &__completed-label {
            top: 1rem;
            left: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 16px;
            font-size: 13px;
            line-height: 18px;
            font-weight: var(--font-weight-regular);
            text-transform: uppercase;

            background: #cccccc;
            border-radius: 3px;
        }
    }

    &__progress {
        margin-top: 1.6rem;

        @media (--tablet) {
            margin-top: 0;
        }
    }

    &__progress--vertical {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 2rem;

        @media (--tablet) {
            margin-top: 0;
        }

        button {
            max-width: 17rem;
            width: 45%;
            background-color: var(--orange);

            @media (--tablet) {
                display: none;
            }
        }

        .topics-tile__progress-bar {
            width: 45%;
            margin-top: 0;

            @media (--tablet) {
                width: 100%;
            }
        }
    }

    &__progress-bar {
        padding: 0;
        width: 100%;

        .progress-bar__wrapper {
            margin: 0.4rem 0;
        }

        .progress-bar__value {
            display: none;

            @media (--tablet) {
                display: block;
            }
        }
    }

    &__image {
        min-height: 20rem;
        width: 100%;
        background-color: #f2f2f2;
    }
    &__badge {
        position: absolute;
        top: 1rem;
        left: 1rem;
        background-color: #ccc;
        font-size: 1.3rem;
        line-height: 1.8rem;
        font-weight: var(--font-weight-regular);
        padding: 0.4rem 1.6rem;
    }
}
