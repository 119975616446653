.invite-modal-conatiner {
  padding: 2rem 2rem;
  
  .closeModal {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;

    svg {
      cursor: pointer;
      height: 2rem;
      width: 2rem;
    }
  }

  .icon-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    svg {
      width: 7rem;
      height: 7rem;
      .cls-1 {
        fill: #ff6e45;
      }
    }
  }

  .email-text-container {
    h1 {
      font-size: 1.8rem;
      line-height: normal;
      padding: 2rem;
      text-align: center;
    }
    button {
      display: flex;
      align-items: center;
      background-color: #07586b;
      color: white;
      border-radius: 20px;
      border: none;
      margin: 0 auto;
      padding: 1rem 2rem;
      &:hover {
        opacity: .6;
      }
      span {
        font-size: 1.8rem;
        margin-right: 1rem;
      }
      svg {
        height: 2rem;
        width: 2rem;
        .cls-1 {
          fill: white !important;
        }
      }
    }
    
    .input-container {
      position: relative;
      border: 1px solid lightgray;
      border-radius: 2rem;
      padding: .2rem .2rem .2rem 1rem;
      display: flex;

      input {
        border: none;
        padding: 0;
        margin: 0;
        border-radius: 
      }
      svg {
        width: 3.5rem;
        height: 3rem;
        background-color: #07586b;
        border-radius: 50%;
        padding: .4rem;
        &:hover {
          opacity: .6;
          cursor: pointer;
        }

        .cls-1 {
          fill: white !important;
        }
      }
    }
  }
}

.loading-container {
  text-align: center;
  margin: 51px auto;
}
.loading-div {
  width: 60px;
  height: 60px;
  margin: 10px auto;
  border-radius: 50%;
  border-top: 4px solid #ff6e45;
  border-right: 4px solid #ff6e45;
  border-bottom: 4px solid #ff6e45;
  border-left: 4px solid transparent;
  animation: spinner 1s ease-in-out infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}