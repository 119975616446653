* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 100%;
    overflow-x: hidden;
    overscroll-behavior-x: none;
}

#root {
    height: 100%;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
}

ul,
ol {
    margin: 0;
    padding: 0;

    list-style: none;
}

button {
    cursor: pointer;
}

figure {
    display: block;
    margin: 0;
}

img {
    max-width: 100%;
}
