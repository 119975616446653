.instructions {
    padding-top: 1.8rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    min-height: var(--survey-min-height);

    @media (--tablet) {
        justify-content: center;
    }

    &__content {
        &__background {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-image: url("../../assets/images/rippl-shape.svg");
            background-repeat: no-repeat;
            background-position: center;
            z-index: -1;
        }

        h3 {
            color: var(--petrol);

            margin-bottom: 2rem;

            @media (--tablet) {
                margin-bottom: 3.8rem;
            }
        }

        p {
            font-size: var(--p-larger);
            line-height: 2.7rem;
            color: var(--dark-gray);
            font-weight: var(--font-weight-regular);

            @media (--tablet) {
                line-height: 3.4rem;
                font-weight: var(--font-weight-light);
            }
        }

        &--image {
            h3:first-of-type {
                margin-top: 0;
            }

            &__wrapper {
                width: 100%;
                max-width: 50rem;
                margin-top: 3.4rem;

                img {
                    object-fit: contain;
                }
            }
        }
        .instructions-container {
            @media screen and (min-width: 600px){
                p {
                    font-size: 2.5rem;
                    margin-bottom: 3rem;
                    font-weight: var(--font-weight-regular);
                }
            }
            ul {
                list-style-type: disc;
                padding-left: 3rem;
                margin-top: 1.5rem;

                li {
                    margin: 1rem 0;
                }
            }
        }
    }

    /* &__background {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;

        svg {
            width: 100%;
            height: 100%;
            transform: scale(2.5);

            @media (--tablet) {
                transform: scale(1);
            }

            @media (--big-screen) {
                transform: scale(0.7);
            }
        }

        @media (--tablet) {
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    } */
}
