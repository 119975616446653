.topics {
    h1,
    h2,
    h3 {
        color: var(--petrol);
        max-width: var(--wide-page-width-minus-gutter);
        margin-left: auto;
        margin-right: auto;

        @media (--tablet) {
            color: var(--black);
        }
    }

    h1 {
        margin-bottom: 2rem;

        @media (--tablet) {
            font-size: var(--h1-big);
            line-height: 6rem;
        }

        svg {
            cursor: pointer;
        }
    }

    hr {
        max-width: var(--wide-page-width-minus-gutter);
    }

    &__description {
        max-width: var(--wide-page-width-minus-gutter);
        margin: 0 auto;
    }

    .section-with-badge {
        &:first-of-type {
            margin-top: 2.8rem;
        }
        &__title {
            font-size: 2.5rem;
            line-height: 3.4rem;

            @media (--tablet) {
                font-size: 3.5rem;
                line-height: 4.2rem;
            }
        }
    }

    &__title {
        position: relative;
        display: flex;
        justify-content: space-between;

        > div {
            display: flex;
            align-items: center;

            svg:first-of-type {
                margin-right: 2.2rem;

                @media (--tablet) {
                    display: none;
                }
            }
        }
    }
}
