.page-spinner-container {
  width: 100%;
  height: calc(100vh - (var(--header-height) + var(--header-margin)));
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-container {
    .spinner {
      width: 100px;
      height: 100px;
      border-top: 5px solid var(--orange);
      border-right: 5px solid var(--orange);
      border-bottom: 5px solid var(--orange);
      border-left: 5px solid transparent;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
    h1 {
      font-size: 2rem;
      margin-top: 2rem;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
