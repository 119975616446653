.qna-container {

  @media screen and (min-width: 800px) {
    max-width: var(--wide-page-width);
    margin: 0 auto;
  }

  .qna-text-container {
    margin: 4rem auto;

    @media screen and (min-width: 800px) {
      margin: 6rem auto;
    }
  }

  h1 {
    font-size: 2rem;
    font-weight: 600;
    color: black;
    line-height: normal;
    margin-bottom: 2rem;
  }
}

.qna-card-container {
  box-shadow: 0px 0px 10px -4px;
  border-radius: 7px;
  width: 100%;
  margin-bottom: 3rem;
  padding: 2rem 2rem;

  @media screen and (min-width: 800px) {
    padding: 5rem 3rem;
  }

  .question-container {
    margin-bottom: 2rem;

    h1 {
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 500;

      span {
        color: #31a368;
        font-weight: 900;
      }

      @media screen and (min-width: 800px) {
        font-size: 2.2rem;
        line-height: 2.2rem;
      }
    }
  }

  .answer-container {
    h1 {
      font-size: 1.6rem;
      font-weight: 100;
      line-height: 2.2rem;

      span {
        color: var(--orange);
        font-weight: 900;
      }

      @media screen and (min-width: 800px) {
        font-size: 1.9rem;
        line-height: 2.2rem;
      }
    }
  }
}